<template>
  <v-card
    class="rounded-lg"
    :flat="flat"
    >
    <v-card-title
      class="justify-center"
      v-if="!root"
      >
      Mis órdenes
    </v-card-title>
    <v-card-text>
      <div
        class="d-flex flex-row"
        style="gap: 8px"
        >
        <div
          style="flex: 1"
          >
          <v-text-field
            outlined
            class="rounded-lg"
            v-model="search"
            clearable
            label="Buscar orden"
            placeholder="Buscar por número de orden o por producto"
            append-icon="mdi-magnify"
            @click:append="fetchOrders"
            @keydown.enter="fetchOrders"
            ></v-text-field>
        </div>

        <div
          v-if="$route.meta.owners"
          >
          <v-btn
            fab
            depressed
            class="rounded-lg"
            color="green darken-1"
            @click="downloadReport"
            >
            <v-icon
              color="white"
              >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </div>
      </div>

      <template
        v-for="(order, i) in orders"
        >
        <v-card
          @click="showDetail(order)"
          class="mb-3"
          :outlined="outlined"
          >
          <v-card-text
            class="d-flex justify-space-between"
            >
            <div
              class="text-left text--primary"
              >
              <div
                class="text-subtitle-2"
                >
                {{ order.number }}
              </div>

              <div
                class="caption"
                >
                {{ order.lineItems.length }} ítem(s)
              </div>

              <div
                class="caption"
                >
                Fecha de creación: {{ (new Date(order.createdAt)).toLocaleDateString() }}
              </div>
            </div>

            <div
              class="d-flex flex-column align-end"
              >
              <div
                class="font-weight-medium"
                >
                {{ order.displayTotalAmount }}
              </div>

              <div>
                <v-chip
                  x-small
                  :color="order.state == 'completed' ? 'success' : ''"
                  >
                  {{ $t('states.' + order.state) }}
                </v-chip>
              </div>

              <div>
                <v-btn
                  v-if="order.state != 'completed' && !root"
                  class="mt-1"
                  color="secondary"
                  outlined
                  small
                  @click="resume(order)"
                  >
                  Reanudar
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <div 
        v-if="pages > 1"
        class="text-center">
        <v-pagination
          v-model="page"
          :length="pages"
          ></v-pagination>
      </div>

      <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        scrollable
        width="800px"
        >
        <Detail
          :currentOrder="order"
          :key="order.id"
          :close="true"
          :admin="false"
          :checkout="false"
          @close="dialog = false"
          />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { OrderService } from '@/services/storageService'
import { Orders } from '@/graphql/queries/orders'

const Detail = () => import(/* webpackPrefetch: true */ "@/components/orders/Detail");

export default {
  data () {
    return {
      order: {},
      orders: [],
      dialog: false,
      page: 1,
      pages: 1,
      search: null,
      timer: null,
    }
  },

  props: {
    flat: {
      required: false,
      default: false,
      type: Boolean
    },

    outlined: {
      required: false,
      default: false,
      type: Boolean
    },

    root: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  
  created () {
    this.fetchOrders()
  },

  components: {
    Detail
  },

  watch: {
    search () {
      if (this.timer) { clearTimeout(this.timer) }

      this.timer = setTimeout( () => {
        this.page = 1
      }, 300)
    },

    page () {
      this.fetchOrders ()
    }
  },

  methods: {
    fetchOrders () {
      this.$apollo.query({
        query: Orders,
        variables: {
          search: this.search,
          page: this.page,
          root: this.root
        },
      }).then( res => {
        this.orders = res.data.orders.orders
        this.pages = res.data.orders.pages
      })
    },

    showDetail (order) {
      this.order = order
      this.dialog = true
    },

    resume (order) {
      OrderService.saveToken(order.orderToken)
      this.$router.push({ name: 'checkout' })
    },

    downloadReport () {
      if (this.$route.meta.owners) {
        axios({
          url: process.env.VUE_APP_RAILS_URL + '/orders', //your url
          method: 'GET',
          headers: {
            'authorization': localStorage.getItem('auth_token')
          },
          responseType: 'blob', // important
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'report.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      } 
    }
  }
}
</script>
